import { Button, Spinner } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import DiamondWeight from "./diamondWeight";
import DiamondColor from "./diamondColor";
import DiamondShape from "./diamondShape";
import DiamondSaturation from "./diamondSaturation";
import DiamondClarity from "./diamondClarity";

const GiaNumber = ({
  giainputError,
  handleGIAInput,
  handleSubmit,
  isActive,
  giainputTextError,
  payload,
  handleOnchange,
  inputError,
  setStepScroll,
  steps,
  activeGia,
  setActiveGia
}) => {
  //const inputRef = useRef(null);
  //console.log("giainputError =>", giainputError)

  const props = {
    payload,
    handleOnchange,
    inputError,
    setStepScroll,
  };

  const handleSubmitChange = (event) => {
    event.preventDefault();
  };

  return (
    <div
      className="diamond-weight-wrapper diamond-weight-container gia_input"
      id="diamondWeight"
    >
            {!activeGia &&
            <div className="giaBack">
              <LeftArrowSVg />
            <p
                onClick={() => {
                  setActiveGia(!activeGia);
                }}
              >
                Generate {activeGia ? "Manually" : "By GIA"}
              </p>
              </div>
              }
      {activeGia ? (
        <Form onSubmit={handleSubmitChange} className="center-gia-input" >
          <div class="rarity-tab-beta " >
          <span class="beta-top1 tab-beta">[BETA]</span>
          <h5>create your rarity report</h5>
          </div>
          <Form.Group
            className={`diamond-weight ${giainputError ? "error-2" : ""}`}
          >
          
            {/* <Form.Label className="rarity-transform ">
              type in the gia number
            </Form.Label> */}
            <Form.Control
            className=""
              type="number"
              placeholder="GIA Report Number"
              pattern="/^\d{7,10}$/"
              name="number"
              onChange={(e) => handleGIAInput(e)}
              //ref={inputRef}
              onWheel={(e) => e.target.blur()}  
              autoFocus={true}
            />
            {giainputTextError && (
              <span className="error-text">
                Please check the number you entered
              </span>
            )}
          </Form.Group>
          <div className="rarity-process-content rarity-gia-btn">
            <Button
              className="processBtn newProcessBtn"
              disabled={giainputError}
              onClick={() => handleSubmit("fromgia")}
              type="submit"
            >
              {isActive ? <Spinner animation="border" /> : "Generate"}
            </Button>
            <div className="manual_generate">
              <div className="generat-ortext">
                <p>OR</p>
              </div>
              <p
                onClick={() => {
                  setActiveGia(!activeGia);
                }}
              >
                Generate {activeGia ? "Manually" : "By GIA"}
              </p>
            </div>
          </div>
        </Form>
      ) : (
        <>
          {steps >= 1 && <DiamondWeight activeGia={activeGia} setActiveGia={setActiveGia} {...props}  />}
          {steps >= 2 && <DiamondColor {...props} />}
          {steps >= 3 && <DiamondShape {...props} />}
          {steps >= 4 && <DiamondSaturation {...props} />}
          {steps >= 5 && <DiamondClarity {...props} />}
          {!activeGia && (steps > 5) && (
                <div className="rarity-process-wrapper process-btn-rarity" id="processBtn">
                 <div className="container">
                  <div className="rarity-process-content">
                  <Button
                    className="processBtn"
                    disabled={steps > 5 ? false : true}
                    onClick={() => handleSubmit("manual")}
                  >
                    {isActive ? <Spinner animation="border" /> : "Generate"}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    
    </div>
    
  );
};
export default GiaNumber;

const LeftArrowSVg=()=>{
  return(
    <svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M26.5 12H3.5" stroke="#D5B65A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10 19L3 12L10 5" stroke="#D5B65A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  )
}