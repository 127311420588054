import { DiaryDiamondShapes } from "../../utils/dairyDiamondsDetail";
import { DiamondColors, DiamondShapes } from "../../utils/diamondsDetail";
import { clientPath } from "../../config/key";
const DiamondCharacteristics = ({ report, imgScaleVal, range_slider }) => {
  const {
    weight,
    saturation,
    color,
    shape,
    clarity,
    report_number,
    colorName,
    giaclarity,
    shapefullname,
  } = report || {};
  // console.log("report", report);
  const { shapeName } = range_slider || {};

  const result = DiamondColors.filter((elem) => color === elem.value);
  let resultShape;
  if (report?.report_number === "" || !report?.report_number) {
    resultShape = DiamondShapes.filter((elem) => shape === elem.value);
  } else {
    resultShape = DiaryDiamondShapes.filter((elem) => shapeName === elem.value);
  }
  const showColorName = result[0]?.value;
  const showColorImg = result[0]?.img;
  const showShapeImg = resultShape[0]?.img;

  return (
    <div className="rarity-tool-card">
      <div className="rarity-card-content">
        <h6>Diamond Characteristics</h6>
        <div className="diamond-characteristics">
          {report_number && report_number !== "" ? (
            <div className="diamond-characteristics-desc">
              <p className="diamond-characteristics-gia">{`GIA NO. ${report_number}`}</p>
              <p>{`Carat: ${weight} carat`}</p>
              <p>{`Saturation: ${saturation}`}</p>
              <p>{`Color: ${colorName ==="Gray Yellowish Green" ? "Gray Yellowish Green - Chameleon" : colorName}`}</p>
              <p title={shapefullname}>{`Shape: ${shapeName}`}</p>
              <p>{`Clarity: ${giaclarity}`}</p>
            </div>
          ) : (
            <div className="diamond-characteristics-desc">
              <p>{`Carat: ${weight} carat`}</p>
              <p>{`Saturation: ${saturation}`}</p>
              <p>{`Color: ${showColorName ==="Chameleon" ? "Gray Yellowish Green - Chameleon" : showColorName || "" }`}</p>
              <p>{`Shape: ${shape}`}</p>
              <p>{`Clarity: ${clarity}`}</p>
            </div>
          )}
          <div className="diamond-characteristics-img">
            <img
              className={`diamond-saturation ${saturation}`}
              src={`${clientPath}/images/diamond-${showColorImg}-${showShapeImg}.svg`}
              alt={showColorImg}
              style={{ transform: `scaleY(${imgScaleVal})` }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiamondCharacteristics;
