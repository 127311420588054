export const DiamondColors = [
  { id: 1, img: "yellow", name: "Yellow", value: "Yellow" },
  {
    id: 2,
    img: "greenish-yellow",
    name: "Green/ish Yellow",
    value: "Green/ish Yellow",
  },
  {
    id: 3,
    img: "orange-yellow",
    name: "Orange Yellow",
    value: "Orange Yellow",
  },
  {
    id: 4,
    img: "orangy-yellow",
    name: "Orangy Yellow",
    value: "Orangy Yellow",
  },
  {
    id: 5,
    img: "pink",
    name: "Pink/Purple/ish Pink",
    value: "Pink/Purple/ish Pink",
  },
  // {
  //   id: 5,
  //   img: "purplish-pink",
  //   name: "Purple/ish Pink",
  //   value: "Pink",
  // },
  { id: 6, img: "orangy-pink", name: "Orangy Pink", value: "Orangy Pink" },
  {
    id: 7,
    img: "pinkish-purple",
    name: "Pink/ish Purple",
    value: "Pink/ish Purple",
  },
  { id: 8, img: "purple", name: "Purple", value: "Purple" },
  {
    id: 9,
    img: "brownish-pink",
    name: "Brownish Pink",
    value: "Brownish Pink",
  },
  { id: 10, img: "brown-pink", name: "Brown Pink", value: "Brown Pink" },
  { id: 11, img: "blue", name: "Blue", value: "Blue" },
  { id: 12, img: "grey-blue", name: "Gray Blue", value: "Gray Blue" },
  { id: 13, img: "green-blue", name: "Green Blue", value: "Green Blue" },
  {
    id: 14,
    img: "greenish-blue",
    name: "Greenish Blue",
    value: "Greenish Blue",
  },
  { id: 15, img: "grayish-blue", name: "Grayish Blue", value: "Grayish Blue" },
  { id: 16, img: "orange", name: "Orange", value: "Orange" },
  {
    id: 17,
    img: "yellow-orange",
    name: "Yellow Orange",
    value: "Yellow Orange",
  },
  {
    id: 18,
    img: "yellowish-orange",
    name: "Yellowish Orange",
    value: "Yellowish Orange",
  },
  {
    id: 19,
    img: "brownish-orange",
    name: "Brownish Orange",
    value: "Brownish Orange",
  },
  {
    id: 20,
    img: "brown-orange",
    name: "Brown Orange",
    value: "Brown Orange",
  },
  { id: 21, img: "green", name: "Green", value: "Green" },
  {
    id: 22,
    img: "yellow-green",
    name: "Yellow/ish Green",
    value: "Yellow/ish Green",
  },
  {
    id: 23,
    img: "bluegreen",
    name: "Blue Green",
    value: "Blue Green",
  },
  {
    id: 24,
    img: "bluish-green",
    name: "Bluish Green",
    value: "Bluish Green",
  },
  { id: 25, img: "red", name: "Red", value: "Red" },
  { id: 26, img: "purplish-red", name: "Purplish Red", value: "Purplish Red" },
  { id: 27, img: "grey", name: "Gray", value: "Gray" },
  { id: 28, img: "blue-grey", name: "Blue/ish Gray", value: "Blue/ish Gray" },

  // { id: 27, img: "purple-brown-grey", value: "Purple with Brown or Gray" },
  // { id: 28, img: "grey-violet", value: "Gray-violet - violet gray" },
  // { id: 29, img: "violet-blue", value: "Violet-blue to blue violet" },
  // { id: 30, img: "violet", value: "Violet" },
  // { id: 31, img: "greyish-yellow-green", value: "Grayish yellow-green" },
  // {
  //   id: 32,
  //   img: "brownish-greenish-yellow",
  //   value: "Brownish greenish yellow",
  // },
  // { id: 33, img: "greyish-green-yellow", value: "Grayish green-yellow" },
  // { id: 34, img: "green-grey", value: "Green gray to gray green" },
  {
    id: 35,
    img: "chameleon",
    name: "Chameleon",
    value: "Chameleon",
  },
];

export const DiamondShapes = [
  // { id: 1, img: "cushion", value: "Cushion / Radiant" },
  { id: 1, img: "cushion", value: "Cushion" },
  { id: 1, img: "radient", value: "Radiant" },
  { id: 2, img: "oval", value: "Oval" },
  { id: 3, img: "heart", value: "Heart" },
  { id: 4, img: "pear", value: "Pear" },
  { id: 5, img: "emerald", value: "Emerald" },
  { id: 6, img: "round", value: "Round" },
  // { id: 1, img: "marquise", value: "Marquise" },
];
export const DiamondClaritys = [
  { diamondType: ["FL or IF"], desc: "Flawless/Internally…" },
  { diamondType: ["VVS"], desc: "Very Very Slightly Inclusion" },
  { diamondType: ["VS"], desc: "Very  Slightly Inclusion" },
  { diamondType: ["SI1"], desc: "Slightly Inclusion" },
  { diamondType: ["SI2"], desc: "Slightly Inclusion" },
  { diamondType: ["I"], desc: "Inclusion or Pique" },
];

export const DiamondSaturations = [
  "Faint",
  "Very Light",
  "Light",
  "Fancy Light",
  "Fancy",
  "Fancy Intense",
  "Fancy Vivid",
  "Fancy Deep",
  "Fancy Dark",
];

export const DiamondAssets = [
  {
    img: "yellow",
    value: "Yellow",

    saturation: [
      "Fancy Light",
      "Fancy",
      "Fancy Intense",
      "Fancy Vivid",
      "Fancy Deep",
      // "Fancy Dark",
    ],
  },
  {
    img: "brownish-greenish-yellow",
    value: "Brownish greenish yellow",

    saturation: [
      "Light",
      "Fancy Light",
      "Fancy",
      "Fancy Intense",
      "Fancy Vivid",
      "Fancy Deep",
      "Fancy Dark",
    ],
  },
  {
    img: "greyish-green-yellow",
    value: "Grayish green-yellow",

    saturation: [
      "Faint",
      "Very Light",
      "Light",
      "Fancy Light",
      "Fancy",
      "Fancy Intense",
      "Fancy Vivid",
      "Fancy Deep",
      "Fancy Dark",
    ],
  },
  {
    img: "greyish-yellow-green",
    value: "Grayish yellow-green",

    saturation: [
      "Faint",
      "Very Light",
      "Light",
      "Fancy Light",
      "Fancy",
      "Fancy Intense",
      "Fancy Vivid",
      "Fancy Deep",
      "Fancy Dark",
    ],
  },
  {
    img: "greenish-yellow",
    value: "Green/ish Yellow",

    saturation: [
      "Light",
      "Fancy Light",
      "Fancy",
      "Fancy Intense",
      "Fancy Vivid",
      "Fancy Deep",
      // "Fancy Dark",
    ],
  },
  {
    img: "orange-yellow",
    value: "Orange Yellow",

    saturation: [
      // "Fancy Light",
      "Fancy",
      "Fancy Intense",
      "Fancy Vivid",
      "Fancy Deep",
      // "Fancy Dark",
    ],
  },
  {
    img: "orangy-yellow",
    value: "Orangy Yellow",

    saturation: [
      // "Fancy Light",
      "Fancy",
      "Fancy Intense",
      "Fancy Vivid",
      "Fancy Deep",
      // "Fancy Dark",
    ],
  },
  {
    img: "blue",
    value: "Blue",

    saturation: [
      "Faint",
      "Very Light",
      "Light",
      "Fancy Light",
      "Fancy",
      "Fancy Intense",
      "Fancy Vivid",
      "Fancy Deep",
      // "Fancy Dark",
    ],
  },
  {
    img: "grey-blue",
    value: "Gray Blue",

    saturation: [
      // "Faint",
      // "Very Light",
      // "Light",
      "Fancy Light",
      "Fancy",
      // "Fancy Intense",
      // "Fancy Vivid",
      // "Fancy Deep",
      "Fancy Dark",
    ],
  },
  {
    img: "green-blue",
    value: "Green Blue",

    saturation: [
      // "Faint",
      // "Very Light",
      // "Light",
      "Fancy Light",
      "Fancy",
      "Fancy Intense",
      "Fancy Vivid",
      "Fancy Deep",
      // "Fancy Dark",
    ],
  },
  {
    img: "greenish-blue",
    value: "Greenish Blue",

    saturation: [
      // "Faint",
      // "Very Light",
      // "Light",
      "Fancy Light",
      "Fancy",
      "Fancy Intense",
      "Fancy Vivid",
      "Fancy Deep",
      // "Fancy Dark",
    ],
  },
  {
    img: "violet-blue",
    value: "Violet-blue to blue violet",

    saturation: [
      "Faint",
      "Very Light",
      "Light",
      "Fancy Light",
      "Fancy",
      "Fancy Intense",
      "Fancy Vivid",
      "Fancy Deep",
      "Fancy Dark",
    ],
  },
  {
    img: "pink",
    value: "Pink",

    saturation: [
      "Faint",
      "Very Light",
      "Light",
      "Fancy Light",
      "Fancy",
      "Fancy Intense",
      "Fancy Vivid",
      "Fancy Deep",
      // "Fancy Dark",
    ],
  },
  {
    img: "pink",
    value: "Purple/ish Pink",

    saturation: [
      "Faint",
      "Very Light",
      "Light",
      "Fancy Light",
      "Fancy",
      "Fancy Intense",
      "Fancy Vivid",
      "Fancy Deep",
      // "Fancy Dark",
    ],
  },
  {
    img: "pink",
    value: "Pink/Purple/ish Pink",

    saturation: [
      "Faint",
      "Very Light",
      "Light",
      "Fancy Light",
      "Fancy",
      "Fancy Intense",
      "Fancy Vivid",
      "Fancy Deep",
      // "Fancy Dark",
    ],
  },
  {
    img: "orangy-pink",
    value: "Orangy Pink",
    saturation: [
      "Fancy Light",
      "Fancy",
      "Fancy Intense",
      "Fancy Vivid",
      "Fancy Deep",
    ],
  },
  {
    img: "green",
    value: "Green",
    saturation: [
      // "Faint",
      "Very Light",
      "Light",
      "Fancy Light",
      "Fancy",
      "Fancy Intense",
      "Fancy Vivid",
      "Fancy Deep",
      // "Fancy Dark",
    ],
  },
  {
    img: "yellow-green",
    value: "Yellow/ish Green",
    saturation: [
      "Faint",
      "Very Light",
      "Light",
      "Fancy Light",
      "Fancy",
      "Fancy Intense",
      "Fancy Vivid",
      "Fancy Deep",
      // "Fancy Dark",
    ],
  },
  {
    img: "violet",
    value: "Violet",

    saturation: ["Fancy Deep", "Fancy Dark"],
  },
  {
    img: "grey-violet",
    value: "Gray-violet - violet gray",

    saturation: [
      "Faint",
      "Very Light",
      "Light",
      "Fancy Light",
      "Fancy",
      "Fancy Intense",
      "Fancy Vivid",
      "Fancy Deep",
      "Fancy Dark",
    ],
  },
  {
    img: "orange",
    value: "Orange",

    saturation: [
      // "Faint",
      // "Very Light",
      // "Light",
      // "Fancy Light",
      "Fancy",
      "Fancy Intense",
      "Fancy Vivid",
      "Fancy Deep",
      // "Fancy Dark",
    ],
  },
  {
    img: "brownish-orange",
    value: "Brownish Orange",
    saturation: [
      // "Fancy Light",
      "Fancy",
      // "Fancy Intense",
      // "Fancy Vivid",
      "Fancy Deep",
      //"Fancy Dark",
    ],
  },
  {
    img: "brown-orange",
    value: "Brown Orange",
    saturation: [
      // "Fancy Light",
      "Fancy",
      // "Fancy Intense",
      // "Fancy Vivid",
      // "Fancy Deep",
      "Fancy Dark",
    ],
  },
  {
    img: "purple",
    value: "Purple",

    saturation: [
      "Faint",
      "Very Light",
      "Light",
      "Fancy Light",
      "Fancy",
      "Fancy Intense",
      "Fancy Vivid",
      "Fancy Deep",
      // "Fancy Dark",
    ],
  },
  {
    img: "red",
    value: "Red",

    saturation: [
      // "Very Light",
      // "Light",
      // "Fancy Light",
      "Fancy",
      // "Fancy Intense",
      // "Fancy Vivid",
      // "Fancy Deep",
      // "Fancy Dark",
    ],
  },
  {
    img: "grey",
    value: "Gray",

    saturation: [
      // "Faint",
      // "Very Light",
      "Light",
      "Fancy Light",
      "Fancy",
      // "Fancy Intense",
      // "Fancy Vivid",
      // "Fancy Deep",
      "Fancy Dark",
    ],
  },
  {
    img: "blue-grey",
    value: "Blue/ish Gray",

    saturation: [
      // "Faint",
      // "Very Light",
      // "Light",
      // "Fancy Light",
      "Fancy",
      // "Fancy Intense",
      // "Fancy Vivid",
      // "Fancy Deep",
      "Fancy Dark",
    ],
  },
  {
    img: "green-grey",
    value: "Green gray to gray green",

    saturation: [
      "Faint",
      "Very Light",
      "Light",
      "Fancy Light",
      "Fancy",
      "Fancy Deep",
      "Fancy Dark",
    ],
  },
  {
    img: "purple-brown-grey",
    value: "Purple with Brown or Gray",

    saturation: [
      "Faint",
      "Very Light",
      "Light",
      "Fancy Light",
      "Fancy",
      "Fancy Intense",
      "Fancy Vivid",
      "Fancy Deep",
      "Fancy Dark",
    ],
  },
  {
    img: "pinkish-brown",
    value: "pinkish-brown",
    saturation: ["Fancy Light", "Fancy", "Fancy Deep"],
  },
  {
    img: "purplish-pink",
    value: "Purplish Pink",
    saturation: [
      // "Faint",
      // "Very Light",
      "Light",
      "Fancy Light",
      "Fancy",
      "Fancy Intense",
      "Fancy Vivid",
      "Fancy Deep",
      // "Fancy Dark",
    ],
  },
  {
    img: "pinkish-purple",
    value: "Pink/ish Purple",
    saturation: [
      // "Faint",
      // "Very Light",
      // "Light",
      "Fancy Light",
      "Fancy",
      "Fancy Intense",
      "Fancy Vivid",
      "Fancy Deep",
      // "Fancy Dark",
    ],
  },
  {
    img: "brown-pink",
    value: "Brown Pink",
    saturation: [
      // "Faint",
      // "Very Light",
      // "Light",
      "Fancy Light",
      "Fancy",
      // "Fancy Intense",
      // "Fancy Vivid",
      // "Fancy Deep",
      "Fancy Dark",
    ],
  },
  {
    img: "grayish-blue",
    value: "Grayish Blue",
    saturation: [
      // "Faint",
      // "Very Light",
      "Light",
      "Fancy Light",
      "Fancy",
      // "Fancy Intense",
      // "Fancy Vivid",
      "Fancy Deep",
      // "Fancy Dark",
    ],
  },
  {
    img: "yellow-orange",
    value: "Yellow Orange",
    saturation: [
      // "Faint",
      // "Very Light",
      // "Light",
      "Fancy Light",
      "Fancy",
      "Fancy Intense",
      "Fancy Vivid",
      "Fancy Deep",
      // "Fancy Dark",
    ],
  },
  {
    img: "yellowish-orange",
    value: "Yellowish Orange",
    saturation: [
      // "Faint",
      // "Very Light",
      // "Light",
      // "Fancy Light",
      "Fancy",
      "Fancy Intense",
      "Fancy Vivid",
      "Fancy Deep",
      // "Fancy Dark",
    ],
  },
  {
    img: "purplish-red",
    value: "Purplish Red",
    saturation: [
      // "Faint",
      // "Very Light",
      // "Light",
      // "Fancy Light",
      "Fancy",
      // "Fancy Intense",
      // "Fancy Vivid",
      // "Fancy Deep",
      // "Fancy Dark",
    ],
  },
  {
    img: "Blueish-green",
    value: "Blueish Green",
    saturation: [
      // "Faint",
      // "Very Light",
      "Light",
      "Fancy Light",
      "Fancy",
      "Fancy Intense",
      "Fancy Vivid",
      "Fancy Deep",
      // "Fancy Dark",
    ],
  },
  {
    img: "brownish-pink",
    value: "Brownish Pink",
    saturation: [
      // "Faint",
      // "Very Light",
      // "Light",
      "Fancy Light",
      "Fancy",
      // "Fancy Intense",
      // "Fancy Vivid",
      "Fancy Deep",
      // "Fancy Dark",
    ],
  },
  {
    img: "bluish-green",
    value: "Bluish Green",
    saturation: [
      // "Faint",
      // "Very Light",
      "Light",
      "Fancy Light",
      "Fancy",
      "Fancy Intense",
      "Fancy Vivid",
      "Fancy Deep",
      // "Fancy Dark",
    ],
  },
  {
    img: "bluegreen",
    value: "Blue Green",
    saturation: [
      // "Faint",
      // "Very Light",
      "Light",
      "Fancy Light",
      "Fancy",
      "Fancy Intense",
      "Fancy Vivid",
      "Fancy Deep",
      // "Fancy Dark",
    ],
  },
  {
    img: "chameleon",
    value: "Chameleon",
    saturation: [
      "Very Light",
      "Light",
      "Fancy Light",
      "Fancy",
      "Fancy Deep",
      "Fancy Dark",
    ],
  },
];
