import axios from 'axios';
export const updateSubMetaField = async(subscription_id,product_id,type, userID) => {
    try{
        const url = `${process.env.REACT_APP_WP_BASE_APIURL}/userSubMetaUp`;
        const response = await axios.post(url, {
          type,
          product_id,       
          subscription_id,
          userID
    });
        const { data } = response;
        const {
          code,
          diary_balance,
          credits_included,
          rarity_cradits,
          is_child_access
        } = JSON.parse(data);
        if(code === 200){
            localStorage.setItem("is_rarity_cradits", rarity_cradits);
            localStorage.setItem("is_credits_included", credits_included);
            localStorage.setItem("is_diary_balance", diary_balance);
            localStorage.setItem("is_child_access", JSON.stringify(is_child_access));

            return JSON.parse(data)
        }
    }catch(e){
        console.log("erooror=>", e)
    }
};

export const getChildUserInfo = async (subscriptionId, userId) => {
    try {
      console.log("id ===>", { subscriptionId, userId });
      const url = `${process.env.REACT_APP_WP_BASE_APIURL}/getChildUserInfo`;
      const response = await axios.post(url, {
        subscription_id: subscriptionId,
        userId: userId
      });
      const { data } = response;
      const {
        code,
        rarity_credits,
        credits_included,
        diary_balance,
        is_child_access
      } = JSON.parse(data); 
      if (code === 200) {
        localStorage.setItem("is_rarity_credits", rarity_credits);
        localStorage.setItem("is_credits_included", credits_included);
        localStorage.setItem("is_diary_balance", diary_balance);
        localStorage.setItem("is_child_access", JSON.stringify(is_child_access));
        return {
          rarity_credits,
          credits_included,
          diary_balance,
        };
      }
      console.log('response   ---------->', JSON.parse(data));
    } catch (e) {
      console.log("e ====>", e);
    }
  };
  
  